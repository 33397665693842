<template>
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">人脸认证统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:10px">
            <div title="学员搜索" class="searchboxItem ci-full">
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="班级名称" class="searchboxItem ">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="课程名称" class="searchboxItem">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="课件名称" class="searchboxItem">
              <span class="itemLabel">课件名称:</span>
              <el-input
                v-model="kpointName"
                type="text"
                size="small"
                placeholder="请输入课件名称"
                clearable
              />
            </div>
          </div>
          <div class="searchbox">
            <div title="认证类型" class="searchboxItem">
              <span class="itemLabel">认证类型:</span>
              <el-select
                v-model="faceType"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in LearningstateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div title="认证状态" class="searchboxItem ">
              <span class="itemLabel">认证状态:</span>
              <el-select
                v-model="faceStates"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in faceStatelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="认证时间" class="searchboxItem">
              <span class="itemLabel">认证时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="OperationTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df">
               <el-button
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <!-- <el-button
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="exportData()"
              >导出</el-button
            > -->
            </div>
           
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="学员姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                minWidth="120"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                minWidth="160"
                fixed
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                minWidth="120"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="150"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                minWidth="150"
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="kpointName"
                minWidth="150"
              />
              <el-table-column
                label="认证类型"
                align="center"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  $setDictionary("FACETYPE", scope.row.faceType)
                }}</template>
              </el-table-column>
              <el-table-column
                label="认证状态"
                align="center"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.faceState == true ? "成功" : "失败"
                }}</template>
              </el-table-column>
              <el-table-column
                label="认证时间"
                align="left"
                show-overflow-tooltip
                width="180"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | moment }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0 5px"
                    size="mini"
                    @click="lookStudyRecord(scope.row.faceId)"
                    >查看认证照片</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisiblePhoto"
      width="700px"
      :before-close="handleClose"
    >
      <section>
        <div class="imgBox">
          <div class="CertificationBox">
            <div class="certificationTitle">
              <span>认证模板</span>
            </div>
            <div class="certifictionImg">
              <el-image
                :src="this.facepicture.userFaceUrl"
                v-if="facepicture.userFaceUrl"
              ></el-image>
              <span v-else>未认证</span>
            </div>
          </div>
          <div class="CaptureBox">
            <div class="CaptureTitle">
              <span>学习抓拍图片</span>
            </div>
            <div class="CaptureList">
              <div class="certifictionImg" v-if="facepicture.approveFaceUrl">
                <el-image @click="viewOriginalImage" :src="this.facepicture.approveFaceUrl"></el-image>
                <p>认证时间: {{ this.facepicture.createTime }}</p>
                <p>认证结果:{{ this.faceState }}</p>
                <p v-if="failReason">
                  失败原因: {{ this.facepicture.faceCause }}
                </p>
              </div>
              <span class="certifictionImg" v-else>暂无数据</span>
            </div>
          </div>
        </div>
      </section>
    </el-dialog>
    <div class="abow_dialog" @click="originalRrawing=false" v-if="originalRrawing">
      <img style="display:block; margin:0 auto;" :src="facepicture.approveSourceFaceUrl" alt="">
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
import { Message } from "element-ui";
export default {
  name: "operate/faceStudentstatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userId: "", //学员Id
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectName: "", //班级名
      kpointName: "", //课件名
      courseName: "", //课程名
      faceType: "", // 认证类型
      LearningstateList: [], // 认证类型
      faceStatelist: [
        {
          value: 1,
          label: "成功",
        },
        {
          value: 0,
          label: "失败",
        },
      ],
      dialogVisiblePhoto: false, //弹出框
      facepicture: "", //人脸照片
      faceState: "", //认证状态,
      faceStates: "",
      failReason: true,
      once: true,
      userPhone: "",
      userIdcard: "",
      OperationTime: "",
      originalRrawing:"",//查看原图可见性
    };
  },
  doNotInit: true,
  created() {
    this.superUserSelect();
    //每天首次进入的时候弹提示--------- 开始
    let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
    //获取当前时间
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    let nowDate = year + "-" + month + "-" + day;
    console.log(localStorage.getItem(userId));
    if (!localStorage.getItem(userId)) {
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: false, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv",
        }
      )
        .then(() => {
          let userKey = {};
          userKey["faceStudentstatistics"] = nowDate;
          localStorage.setItem(userId, JSON.stringify(userKey));
        })
        .catch(() => {});
    } else if (
      JSON.parse(localStorage.getItem(userId)).faceStudentstatistics ==
        undefined ||
      JSON.parse(localStorage.getItem(userId)).faceStudentstatistics < nowDate
    ) {
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: false, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv",
        }
      )
        .then(() => {
          let userKey = JSON.parse(localStorage.getItem(userId));
          userKey.faceStudentstatistics = nowDate;
          localStorage.setItem(userId, JSON.stringify(userKey));
        })
        .catch(() => {});
    }
    //每天首次进入的时候弹提示-------- 结束
  },
  mounted() {
    this.getTableHeight();
    this.getLearningstateList();
  },
  methods: {
    // 点击图片查看原图
    viewOriginalImage(){
      this.originalRrawing=true;
    },
    //清除学员信息
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    getData(pageNum = 1) {
      if (this.userId == "") {
        Message.error({
          message: "请先选择学员",
          duration: "2000",
        });
        return;
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userId: this.userId,
        faceState: this.faceStates,
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      if (this.OperationTime && this.OperationTime.length > 0) {
        params.startDate = this.OperationTime[0];
        params.endDate = this.OperationTime[1];
      }
      if (this.faceType) {
        params.faceType = this.faceType;
      }
      this.doFetch({
        url: "/biz/userface/listPage",
        params,
        pageNum,
      });
    },
    getLearningstateList() {
      const LearningstateList = this.$setDictionary("FACETYPE", "list");
      const list = [];
      for (const key in LearningstateList) {
        list.push({
          value: key,
          label: LearningstateList[key],
        });
      }
      this.LearningstateList = list;
    },
    /* 查看认证照片 */
    lookStudyRecord(id) {
      this.dialogVisiblePhoto = true;
      this.$post("/gov/project/queryUserFace", { faceId: id })
        .then((res) => {
          this.facepicture = res.data;
          if (this.facepicture.faceState == true) {
            this.failReason = false;
            return (this.faceState = "认证成功");
          } else {
            this.failReason = true;
            return (this.faceState = "认证失败");
          }
        })
        .catch(() => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 60 + 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    .el-cascader {
      height: 32px !important;
    }
    
  }
}
.abow_dialog{
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.imgBox {
  display: flex;
  border: 1px solid #797979;
  .CertificationBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    border-right: 1px solid #797979;
    .certificationTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 210px;
        height: 230px;
      }
    }
  }
  .CaptureBox {
    flex: 1;
    width: 500px;
    .CaptureTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .CaptureList {
      flex: 1;
      overflow-y: auto;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 100%;
        height: 230px;
      }
      p {
        padding: 5px 0;
      }
    }
  }
}
</style>
